import { TagsRepository } from "../repositories/TagsRepository";
import {TreesRepository} from "../repositories/TreesRepository";
import {buildTree} from "../models/Tree";
import {buildForest} from "../models/Forest";

export const ForestApplication = {
    /**
     * Retrieves the forest from the repository, which consists of a list of trees and their tags.
     * @returns {Promise<Forest>}
     */
    fetchForest: () => {
        try {
            return TreesRepository.get()
                .then(async treeList => {
                    const tagsListByTree = await TagsRepository.get();
                    const treesWithTags = tagsListByTree.map((treeWithTags, i) => {
                        const currentTreeInfo = treeList.find(tree => tree.getId() === treeWithTags.treeId);
                        return buildTree({
                            id: treeWithTags.treeId,
                            tags: treeWithTags.tags,
                            position: currentTreeInfo.getPosition() >= 0 ? currentTreeInfo.getPosition() : i,
                            color: currentTreeInfo.getColor(),
                            name: currentTreeInfo.getName(),
                            permission: currentTreeInfo.getPermission(),
                            rootTagId: currentTreeInfo.getRootTagId()
                        })
                    });
                    return buildForest({ trees: treesWithTags });
                }, (error) => {
                    throw error;
                })

        } catch (e) {
            throw e;
        }
    },
    moveTag: function moveTag(tag, newParentTag) {
        return TagsRepository.move(tag, newParentTag);
    }
}